exports.components = {
  "component---src-pages-condiciones-legales-js": () => import("./../../../src/pages/condiciones-legales.js" /* webpackChunkName: "component---src-pages-condiciones-legales-js" */),
  "component---src-pages-consulta-experto-js": () => import("./../../../src/pages/consulta-experto.js" /* webpackChunkName: "component---src-pages-consulta-experto-js" */),
  "component---src-pages-consulta-pago-completado-js": () => import("./../../../src/pages/consulta-pago-completado.js" /* webpackChunkName: "component---src-pages-consulta-pago-completado-js" */),
  "component---src-pages-contacto-enviado-js": () => import("./../../../src/pages/contacto-enviado.js" /* webpackChunkName: "component---src-pages-contacto-enviado-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contratar-dictamen-js": () => import("./../../../src/pages/contratar-dictamen.js" /* webpackChunkName: "component---src-pages-contratar-dictamen-js" */),
  "component---src-pages-cuestionario-autovaloracion-js": () => import("./../../../src/pages/cuestionario-autovaloracion.js" /* webpackChunkName: "component---src-pages-cuestionario-autovaloracion-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-disculpe-molestias-js": () => import("./../../../src/pages/disculpe-molestias.js" /* webpackChunkName: "component---src-pages-disculpe-molestias-js" */),
  "component---src-pages-encargo-dictamen-js": () => import("./../../../src/pages/encargo-dictamen.js" /* webpackChunkName: "component---src-pages-encargo-dictamen-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-formulario-contacto-enviado-js": () => import("./../../../src/pages/formulario-contacto-enviado.js" /* webpackChunkName: "component---src-pages-formulario-contacto-enviado-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modelos-dictamen-js": () => import("./../../../src/pages/modelos-dictamen.js" /* webpackChunkName: "component---src-pages-modelos-dictamen-js" */),
  "component---src-pages-negligencia-como-obtener-informacion-js": () => import("./../../../src/pages/negligencia-como-obtener-informacion.js" /* webpackChunkName: "component---src-pages-negligencia-como-obtener-informacion-js" */),
  "component---src-pages-negligencia-danio-moral-js": () => import("./../../../src/pages/negligencia-danio-moral.js" /* webpackChunkName: "component---src-pages-negligencia-danio-moral-js" */),
  "component---src-pages-negligencia-no-tiene-documentacion-js": () => import("./../../../src/pages/negligencia-no-tiene-documentacion.js" /* webpackChunkName: "component---src-pages-negligencia-no-tiene-documentacion-js" */),
  "component---src-pages-negligencia-reclamacion-viable-js": () => import("./../../../src/pages/negligencia-reclamacion-viable.js" /* webpackChunkName: "component---src-pages-negligencia-reclamacion-viable-js" */),
  "component---src-pages-negligencia-saber-abogado-actua-correctamente-js": () => import("./../../../src/pages/negligencia-saber-abogado-actua-correctamente.js" /* webpackChunkName: "component---src-pages-negligencia-saber-abogado-actua-correctamente-js" */),
  "component---src-pages-negligencia-sin-perjucio-economico-js": () => import("./../../../src/pages/negligencia-sin-perjucio-economico.js" /* webpackChunkName: "component---src-pages-negligencia-sin-perjucio-economico-js" */),
  "component---src-pages-negligencia-solo-informacion-js": () => import("./../../../src/pages/negligencia-solo-informacion.js" /* webpackChunkName: "component---src-pages-negligencia-solo-informacion-js" */),
  "component---src-pages-nuestros-servicios-js": () => import("./../../../src/pages/nuestros-servicios.js" /* webpackChunkName: "component---src-pages-nuestros-servicios-js" */),
  "component---src-pages-pago-servicio-js": () => import("./../../../src/pages/pago-servicio.js" /* webpackChunkName: "component---src-pages-pago-servicio-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-servicios-profesionales-js": () => import("./../../../src/pages/servicios-profesionales.js" /* webpackChunkName: "component---src-pages-servicios-profesionales-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-casos-reales-archive-js": () => import("./../../../src/templates/casos-reales-archive.js" /* webpackChunkName: "component---src-templates-casos-reales-archive-js" */)
}

